<template>
  <div class="main-register">
    <div class="view">
      <div class="title">注册</div>
      <div class="sj-title">手机号</div>
      <input class="input-title" type="text" v-model="phone" placeholder="请输入手机号">
      <div class="code-title">验证码</div>
      <div class="code-view">
        <input class="input-title" type="text" v-model="code" placeholder="请输入验证码">
        <div class="codeBtn" @click.stop="sendMethod()" :style="{color:isSkin}">{{codeBtn}}</div>
      </div>
      <div class="pwd-title">密码</div>
      <input class="input-title" type="password" v-model="pwd" placeholder="请输入密码">
      <div class="qrpwd-title">确认密码</div>
      <input class="input-title" type="password" v-model="surePwd" placeholder="请输入确认密码">
      <div class="checkbox-view">
        <el-checkbox v-model="checked" @change="changeMethod">我同意</el-checkbox>
        <div class="xieyi" @click.stop="xieyiMethod()" :style="{color:isSkin}">《用户协议》</div>
        <div class="he">和</div>
        <div class="zhengce" @click.stop="zhengceMethod()" :style="{color:isSkin}">《隐私政策》</div>
      </div>
      <div class="register" :style="{backgroundColor:isSkin}" @click.stop="registerMethod()">注册</div>
      <div class="gologin" @click.stop="goLoginMethod()">已有账号，去登录</div>
    </div>
  </div>
</template>

<script>
import {Message} from 'element-ui'
export default {
  data() {
    return {
      phone:'',
      code:'',
      pwd:'',
      surePwd:'',
      checked:false,
      codeBtn: '获取验证码',
      isClick: true,
    }
  },
  created(){
    this.$store.commit('changClick',1)
    var skin = sessionStorage.getItem('Skin')
    var zskin = sessionStorage.getItem('ZSkin')
    if (skin) {
        this.$store.commit('changSkin',skin)
    }
    if (zskin) {
        this.$store.commit('changZSkin',zskin)
    }
  },
  computed:{
    isSkin(){
		return this.$store.state.isSkin
    }
  },
  methods:{
    /**发送验证码**/
    sendMethod(){
      var that = this;
			let count = 60;
			if (that.phone == '') {
        Message({
          showClose: true,
          message: '请输入手机号！',
          type: 'error'
        })
			}else if (that.isClick) {
        that.$api.getSmsCode({
          mobile:that.phone,
        }).then(res=>{
          console.log(res);
          if (res.data.code == 0) {
            Message({
              showClose: true,
              message: '已发送，请注意查收',
              type: 'success'
            })
            that.isClick = false;
            let timer = setInterval(function() {
              count--;
              that.codeBtn = '获取中(' + count + ')';
              if (count == 0) {
                that.isClick = true;
                clearInterval(timer)
                that.codeBtn = '重新获取';
              }
            }, 1000)
					}else{
            that.isClick = true;
          }
        })
      } 
    },
    changeMethod(index){
      this.checked = index
      if (index == true) {
        /**改变选中时背景颜色**/
        this.$nextTick(function () {
          var array = document.querySelectorAll(".el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner"); 
          if(array && array.length>0){
            array.forEach((item,index1) => {
                array[index1].style.backgroundColor  = this.isSkin;
                array[index1].style.borderColor  = this.isSkin;
            })
          }
        });
        this.$nextTick(function () {
          var array = document.querySelectorAll(".el-checkbox__input.is-checked+.el-checkbox__label "); 
          if(array && array.length>0){
            array.forEach((item,index1) => {
                array[index1].style.color  = "#666";
            })
          }
        });
      }else{
        /**改变未选中时背景颜色**/
        this.$nextTick(function () {
          var array = document.querySelectorAll(".el-checkbox__inner"); 
          if(array && array.length>0){
            array.forEach((item,index1) => {
                array[index1].style.backgroundColor  = "#FFF";
                array[index1].style.borderColor  = "#DDD";
            })
          }
        });
      }
    },
    /**协议按钮**/
    xieyiMethod(){
      this.$router.push('/aboutUs');
      this.$store.commit('changClick',1)
      this.$store.commit('changWe',6)
			sessionStorage.setItem('changWe',6)
    },
    /**隐私按钮**/
    zhengceMethod(){
      this.$router.push('/aboutUs');
      this.$store.commit('changClick',1)
      this.$store.commit('changWe',7)
			sessionStorage.setItem('changWe',7)
    },
    /**注册按钮**/
    registerMethod(){
      var that = this
      if (that.phone == '') {
        Message({
          showClose: true,
          message: '请输入手机号！',
          type: 'error'
        })
        return
			}
      if (that.code == '') {
        Message({
          showClose: true,
          message: '请输入验证码！',
          type: 'error'
        })
        return
			}
      if (that.pwd == '') {
        Message({
          showClose: true,
          message: '请输入密码！',
          type: 'error'
        })
        return
			}
      if (that.surePwd == '') {
        Message({
          showClose: true,
          message: '请输入确认密码！',
          type: 'error'
        })
        return
			}
      if (that.surePwd != that.pwd) {
        Message({
          showClose: true,
          message: '确认密码不正确，请重新输入！',
          type: 'error'
        })
        return
			}
      if (!that.checked) {
        Message({
          showClose: true,
          message: '请勾选协议！',
          type: 'error'
        })
        return
      }
      that.$api.getRegister({
        mobile:that.phone,
        code:that.code,
        password:that.pwd
      }).then(res=>{
        if (res.data.code == 0) {
          Message({
            showClose: true,
            message: '注册成功，快去登录吧！',
            type: 'success'
          })
          setTimeout(()=>{
            this.$router.push('/login');
            this.$store.commit('changClick',1)
          },2000)
        }
      })
    },
    /**去登录**/
    goLoginMethod(){
      this.$router.push('/login');
      this.$store.commit('changClick',1)
    }
  }
}
</script>

<style scoped lang="scss">
.main-register{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .view{
    width: 427px;
    height: 654px;
    background: #FCFCFC;
    margin-top: 50px;
    margin-bottom: 145px;
    .title{
      font-size: 24px;
      line-height: 34px;
      color: #333333;
      margin-left: 20px;
      margin-top: 30px;
    }
    .sj-title{
      font-size: 14px;
      line-height: 20px;
      margin-left: 20px;
      margin-top: 40px;
      color: #333333;
    }
    .code-title{
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      margin-left: 20px;
      margin-top: 20px;
    }
    .code-view{
      position: relative;
      display: flex;
      justify-items: center;
      align-items: center;
      .codeBtn{
        height: 40px;
        position: absolute;
        right: 30px;
        top: 10px;
        font-size: 14px;
        line-height: 40px;
        cursor: pointer;
      }
    }
    .pwd-title{
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      margin-left: 20px;
      margin-top: 20px;
    }
    .qrpwd-title{
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      margin-left: 20px;
      margin-top: 20px;
    }
    .checkbox-view{
      margin-left: 20px;
      margin-top: 40px;
      display: flex;
      justify-items: center;
      align-items: center;
      .xieyi{
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
      }
      .he{
        font-size: 12px;
        line-height: 18px;
        color: #666;
      }
      .zhengce{
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
      }
    }
    .register{
      border-radius: 4px;
      width: 387px;
      height: 42px;
      font-size: 16px;
      line-height: 42px;
      text-align: center;
      color: #FFFFFF;
      margin-left: 20px;
      margin-top: 40px;
      cursor: pointer;
    }
    .gologin{
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      width: 112px;
      height: 20px;
      margin-left: 294px;
      margin-top:20px;
      cursor: pointer;
    }
  }
}
.input-title{
  width: 387px;
  height: 40px;
  background: #FCFCFC;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  outline:none; //去点击蓝色边框
  padding-left: 10px;
  padding-right: 100px;
  font-size: 15px;
  line-height: 40px;
  color: #666666;
  margin-left: 20px;
  margin-top: 10px;
}
</style>